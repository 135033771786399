@charset "UTF-8";
/*====================
/1 Reset et mixins
======================*/
body, h1, h2, h3, h4, h5, h6, p, blockquote, pre, dl, dt, dd, ol, ul, li, form, fieldset, legend, table, th, td, caption, hr {
  margin: 0;
  padding: 0;
}

body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

fieldset, img {
  border: 0;
}

img {
  font-style: italic;
  vertical-align: bottom;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

abbr[title], acronym[title], dfn[title] {
  cursor: help;
  border-bottom: 1px dotted;
}

a {
  text-decoration: none;
  background: transparent;
}

pre {
  overflow: auto;
}

pre, code {
  font-family: monospace;
}

b, strong {
  font-weight: bold;
}

sub, sup {
  font-size: 65%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

small {
  font-size: 80%;
}

button, input, select, option, optgroup, textarea {
  font: inherit;
  color: inherit;
  margin: 0;
}

[type=submit], [type=button], [type=reset], button {
  cursor: pointer;
  border: 0;
  overflow: visible;
  line-height: normal;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

[type=checkbox], [type=radio] {
  padding: 0;
}

textarea {
  overflow: auto;
  resize: none;
}

/* HTML5 */
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

/* Box model */
html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *::before, *::after {
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

/*
center block
*/
/* font-size */
/*
create pseudo element
*/
/*
grids
*/
/*
css triangle
*/
/*
css bubble
*/
/*
placeholder
*/
/*
truncate
*/
/* icons */
/*====================
/2 variables mixins et fonts
======================*/
/*====================
/3 utilitaires du framework
======================*/
/* page wrapper
================ */
.wrapper {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

/* Balises
=====================*/
html {
  min-height: 100%;
  line-height: 1.333;
  font-size: 62.5%;
}

body {
  font-size: 14px;
  font-size: 1.4rem;
}

/* Styler les éléments généraux */
img {
  max-width: 100%;
  height: auto;
}

a[href^=tel] {
  color: inherit;
}

label {
  cursor: pointer;
}

/* Buttons
=====================*/
[class*=-btn],
[class*=btn-] {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  border: none;
  font-size: 15px;
  line-height: normal;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
}
[class*=-btn]:hover,
[class*=btn-]:hover {
  text-decoration: none;
}

/*=====================================*/
/* Icons
================ */
[class^=ico-] {
  font-size: 0;
  display: inline-block;
  position: relative;
}
[class^=ico-]::before {
  content: "";
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 1.4rem;
}

.ico-txt {
  text-indent: -9999em;
  text-align: left;
  overflow: hidden;
}

/*=====================================*/
/* Grid
================ */
/**
 * Fluid Grid system, with percent %
 * Parent `grids` only contain `grid-*` as child
 * Unit "grid" must begin with class `grid-*`
 * Example :
 * OK
 <div class="grids">
  <div class="grid-1-2"></div>
 </div>
 **********
 * Not OK
 <div class="grids">
  <h2>Titre</h2>
  <div class="grid-1-2"></div>
 </div>
 **********
 * Not OK
 <div class="grids">
  <div class="box-border grid-1-2"></div>
 </div>
 * Keep structure with indent `.grids>.grid-*`
 <div class="grids">
  <div class="grid-1-2">
    <div class="grids">
      <div class="grid-1-3"></div>
      <div class="grid-1-3"></div>
      <div class="grid-1-3"></div>
    </div>
  </div>
  <div class="grid-1-2"></div>
 </div>
 */
.grids {
  margin-left: -30px;
  letter-spacing: -0.31em;
  font-size: 0;
  /* Corriger le problème avec les polices non web-safe */
  font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;
}

.opera:-o-prefocus, .grids {
  word-spacing: -0.43em;
  /* Fix for Opera */
}

/* La classe doit absolument commencer par .grid- */
[class^=grid-] {
  padding-left: 30px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  word-spacing: normal;
  letter-spacing: normal;
  font-size: 14px;
  font-size: 1.4rem;
}

/* Grille sans marge */
/* .grids */
.grids-flush {
  margin-left: 0;
}

.grids-flush > [class^=grid-] {
  padding-left: 0;
}

/* Grille marges /2 */
/* .grids */
.grids-small {
  margin-left: -15px;
}

.grids-small > [class^=grid-] {
  padding-left: 15px;
}

/* Grille marges x1.5 */
/* .grids */
.grids-inter {
  margin-left: -45px;
}

.grids-inter > [class^=grid-] {
  padding-left: 45px;
}

/* Grille marges x2 */
/* .grids */
.grids-large {
  margin-left: -60px;
}

.grids-large > [class^=grid-] {
  padding-left: 60px;
}

/* Grille marges x3 */
/* .grids */
.grids-larger {
  margin-left: -90px;
}

.grids-larger > [class^=grid-] {
  padding-left: 90px;
}

/* Grille alignée au milieu */
/* .grids */
.grids-middle > [class^=grid-] {
  vertical-align: middle;
}

/* Grille alignée en bas */
/* .grids */
.grids-bottom > [class^=grid-] {
  vertical-align: bottom;
}

/**
 * Largeurs des grilles
 Utiliser le mixin pour ajouter les grilles selon la maquette
 ex en dessous pour la grille 4 et 12 colonnes
 */
.grids .grid-1-4 {
  width: 25%;
}
.grids .grid-2-4 {
  width: 50%;
}
.grids .grid-3-4 {
  width: 75%;
}
.grids .grid-4-4 {
  width: 100%;
}
.grids .grid-1-12 {
  width: 8.3333333333%;
}
.grids .grid-2-12 {
  width: 16.6666666667%;
}
.grids .grid-3-12 {
  width: 25%;
}
.grids .grid-4-12 {
  width: 33.3333333333%;
}
.grids .grid-5-12 {
  width: 41.6666666667%;
}
.grids .grid-6-12 {
  width: 50%;
}
.grids .grid-7-12 {
  width: 58.3333333333%;
}
.grids .grid-8-12 {
  width: 66.6666666667%;
}
.grids .grid-9-12 {
  width: 75%;
}
.grids .grid-10-12 {
  width: 83.3333333333%;
}
.grids .grid-11-12 {
  width: 91.6666666667%;
}
.grids .grid-12-12 {
  width: 100%;
}

/* Moitiés */
.grid-half {
  width: 50%;
}

/* Auto */
.grid-auto {
  width: auto;
}

/* 100% */
.grid-full {
  width: 100%;
}

/*=====================================*/
/* Media object
================== */
/**
 * Permet d'afficher du contenu à côté d'une image ou d'un élément
<div class="media">
    <div class="media-item"></div>
    <div class="media-body"></div>
</div>
 * ou
 <div class="media">
     <div class="media-item-rev"></div>
     <div class="media-body"></div>
 </div>
 */
.media:after {
  content: "";
  display: table;
  clear: both;
}

.media-body {
  overflow: hidden;
}

.media-item {
  float: left;
  margin-right: 30px;
}

/* Image à droite */
.media-item-rev {
  float: right;
  margin-left: 30px;
}

/* Pas d'espace entre l'image et le contenu */
.media-flush > .media-item,
.media-flush > .media-item-rev {
  margin-left: 0;
  margin-right: 0;
}

/* Espace divisé par 2 */
.media-small > .media-item {
  margin-right: 15px;
}

.media-small > .media-item-rev {
  margin-left: 15px;
}

/* Espace multiplié par 1.5 */
.media-inter > .media-item {
  margin-right: 45px;
}

.media-inter > .media-item-rev {
  margin-left: 45px;
}

/* Espace multiplié par 2 */
.media-large > .media-item {
  margin-right: 60px;
}

.media-large > .media-item-rev {
  margin-left: 60px;
}

/* Espace multiplié par 3.5 */
.media-larger > .media-item {
  margin-right: 105px;
}

.media-larger > .media-item-rev {
  margin-left: 105px;
}

/*=====================================*/
/* Alignement vertical
========================= */
/**
 * Permet d'aligner verticalement un contenu lorsque ses dimensions ainsi que celles de son parent sont inconnues
<div class="v-align-wrap">
    <div class="v-align"></div>
</div>
 */
.v-align-wrap {
  letter-spacing: -0.31em;
  /* Corriger le problème avec les polices non web-safe */
  font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;
}

.opera:-o-prefocus, .v-align-wrap {
  word-spacing: -0.43em;
  /* Fix for Opera */
}

.v-align-wrap::before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.v-align {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: normal;
  word-spacing: normal;
  max-width: 99.9%;
}

/* Navigation
=====================*/
.nav > li,
.nav > li > a,
.list-inline > li,
.list-inline > li > a {
  display: inline-block;
}

/* Navigation verticale */
.nav-vertical > li,
.children > li {
  display: list-item;
}

/* Trigger sous-menu */
.has-children {
  position: relative;
}

/* Sous-menu */
.nav-children .children {
  position: absolute;
  top: 100%;
  left: -99999em;
}

.nav-children .children > li {
  min-width: 200px;
  float: left;
}

.nav-children .children > li > a {
  display: block;
}

/* Apparition sous-menu */
.nav-children .has-children:hover > .children {
  left: 0;
}

/* Second niveau */
.nav-children .children .has-children:hover > .children {
  top: 0;
  left: 100%;
}

/*=====================================*/
/* Formulaires (form)
==============================*/
form {
  /**
   * Specific style for select 
   * Customise `select` without JS
  <span class="dropdown">
    <select></select>
  </span> 
   */
  /**
   * input Radio et Checkbox 
  <label class="option-label option--checkbox">
    <input type="checkbox">
    Label
    <i class="option-input"></i>
  </label>
  <label class="option-label option--radio">
    <input type="radio">
    Label
    <i class="option-input"></i>
  </label>
   */
  /* Survol */
  /* Checkbox */
  /* Radio */
}
form .dropdown {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border: 1px solid;
  background: #FFF;
  vertical-align: middle;
}
form .dropdown:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -3px;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-width: 6px 5px 0 5px;
  border-top-color: #000;
  z-index: 1;
  pointer-events: none;
}
form .dropdown select {
  border: none;
  padding-right: 12px;
  width: 110%;
  max-width: 110%;
  min-width: 190px;
  height: 28px;
  background: none;
  appearance: none;
  -webkit-appearance: none;
}
form .dropdown select::-ms-expand {
  display: none;
}
form .dropdown select:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px rgba(0, 127, 162, 0.3);
}
form .option-label {
  position: relative;
  display: inline-block;
  padding-left: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-size: 1.4rem;
  width: 13px;
  height: 13px;
  vertical-align: middle;
}
form .option-label [type=checkbox], form .option-label [type=radio] {
  position: relative;
  opacity: 0;
  width: inherit;
  height: inherit;
  z-index: 1;
}
form .option-input {
  position: absolute;
  display: block;
  border: 1px solid #B4B3B7;
  width: inherit;
  height: inherit;
  background: #FFF;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 0;
}
form .option-label:hover .option-input,
form .option-label:focus .option-input {
  border-color: #818084;
}
form .option--checkbox .option-input {
  font-size: 60%;
  -webkit-border-radius: 10%;
  border-radius: 10%;
}
form .option--checkbox .option-input::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: inherit;
  z-index: 0;
}
form .option--checkbox input:checked ~ .option-input::before {
  background-size: 12px 12px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMzIgMzIiPjxnIGlkPSJpY29tb29uLWlnbm9yZSI+PGxpbmUgc3Ryb2tlLXdpZHRoPSIxIiB4MT0iIiB5MT0iIiB4Mj0iIiB5Mj0iIiBzdHJva2U9IiM0NDlGREIiIG9wYWNpdHk9IiI+PC9saW5lPjwvZz48cGF0aCBkPSJNMTMuMjcyIDI3LjJjLTAuNjk2IDAtMS4zNTUtMC4zMjUtMS43NzgtMC44ODVsLTUuNzE4LTcuNTQ5Yy0wLjc0NC0wLjk4Mi0wLjU1LTIuMzgxIDAuNDMyLTMuMTI1IDAuOTg0LTAuNzQ2IDIuMzgxLTAuNTUgMy4xMjUgMC40MzJsMy43NjIgNC45NjYgOS40NTktMTUuMTg2YzAuNjUxLTEuMDQ1IDIuMDI3LTEuMzY1IDMuMDc0LTAuNzE0IDEuMDQ1IDAuNjUgMS4zNjUgMi4wMjcgMC43MTIgMy4wNzJsLTExLjE3MyAxNy45MzZjLTAuMzg5IDAuNjI2LTEuMDU5IDEuMDE4LTEuNzk0IDEuMDUwLTAuMDM0IDAuMDAyLTAuMDY3IDAuMDAyLTAuMTAxIDAuMDAyeiIgZmlsbD0iIzIxMjEyMSI+PC9wYXRoPjwvc3ZnPg==);
}
form .option--radio .option-input {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
form .option--radio .option-input::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 2px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
form .option--radio input:checked ~ .option-input::before {
  background: #2F2F2F;
}

/* Formulaire en ligne (+.layout-form-inline) */
.layout-form-inline p {
  clear: both;
}

.layout-form-inline label {
  display: inline;
  padding-right: 10px;
}

.layout-form-inline .copy {
  width: auto;
  padding: 0;
}

.layout-form-inline .copy, .layout-form-inline .ui-state-error {
  margin-left: 160px;
}

.layout-form-inline .error {
  display: block;
}

/*=====================================*/
/* helper Classes
==============================*/
.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.flleft {
  float: left;
}

.flright {
  float: right;
}

.for-print, .no-display {
  display: none;
}

.hidden {
  position: fixed;
  top: -9999em;
  left: -9999em;
  overflow: hidden;
}

br.clear {
  clear: both;
  line-height: 1px;
  height: 0;
  font-size: 1px;
}

.cf::before {
  content: " ";
  display: table;
}
.cf::after {
  content: "";
  display: table;
  clear: both;
}

.flex {
  display: flex;
}

::-webkit-input-placeholder {
  color: #000;
  font-weight: 600;
}

::-moz-placeholder {
  color: #000;
  font-weight: 600;
}

:-ms-input-placeholder {
  color: #000;
  font-weight: 600;
}

:-moz-placeholder {
  color: #000;
  font-weight: 600;
}

.form-submit {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  color: #000;
  border: 4px solid #000;
  background-color: #FFF;
  font-size: 12px;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 10px 25px;
}

button.btn-submit {
  -webkit-appearance: none;
  position: relative;
}
button.btn-submit > span {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: #FFF;
  font-size: 12px;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  display: block;
  height: 43px;
  position: relative;
  z-index: 1;
  display: flex;
}
button.btn-submit > span span {
  width: 100%;
  padding: 10px 25px;
  position: relative;
  border: 4px solid #000;
  background-color: #FFF;
  top: 0;
}
button.btn-submit > span::after {
  content: "";
  height: 38px;
  width: 100%;
  position: absolute;
  left: 8px;
  bottom: -8px;
  background: url(../images/buttonafter-capellia.svg) repeat left top #E6E6E6;
  background-size: 18px 18px;
  z-index: -1;
}
button.btn-submit:hover > span::after {
  animation: barberpole 10s linear infinite;
}

.node-type-page-de-base-jam .form-actions::after, .node-type-page-de-base-jam .views-submit-button::after, .node-type-page-de-base-jam button.btn-submit > span::after,
.node-type-webform-jam .form-actions::after, .node-type-webform-jam .views-submit-button::after, .node-type-webform-jam button.btn-submit > span::after {
  background: url(../images/buttonafter.svg) left top #FFCA45;
  background-size: 18px 18px;
}

form label {
  display: block;
  font-weight: bold;
  color: #000;
  text-transform: none;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 28px;
}

.form-item {
  padding: 0;
  margin: 0;
}
.form-item .form-checkboxes .form-item, .form-item .form-radios .form-item {
  margin-bottom: 0;
  margin-top: 0;
  position: relative;
}
.form-item .form-checkboxes .form-item input, .form-item .form-radios .form-item input {
  position: absolute;
  top: 0;
  left: -9999px;
  visibility: hidden;
}
.form-item .form-checkboxes .form-item label, .form-item .form-radios .form-item label {
  margin-left: 0;
  padding: 0px 0 0px 30px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  text-transform: none;
  color: #000;
}
.form-item .form-checkboxes .form-item label::before, .form-item .form-checkboxes .form-item label::after, .form-item .form-radios .form-item label::before, .form-item .form-radios .form-item label::after {
  content: "";
  position: absolute;
  top: 53%;
  border-radius: 50%;
}
.form-item .form-checkboxes .form-item label::before, .form-item .form-radios .form-item label::before {
  left: 0;
  width: 21px;
  height: 21px;
  margin: -12px 0 0;
  background: #FFF;
  border: 2px solid #000;
}
.form-item .form-checkboxes .form-item label::after, .form-item .form-radios .form-item label::after {
  left: 4px;
  width: 13px;
  height: 13px;
  margin: -8px 0 0;
  opacity: 0;
  background: #000;
  -webkit-transform: translate3d(-40px, 0, 0) scale(0.5);
  transform: translate3d(-40px, 0, 0) scale(0.5);
  transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
.form-item .form-checkboxes .form-item input[type=checkbox] + label:before, .form-item .form-checkboxes .form-item input[type=checkbox] + label:after, .form-item .form-radios .form-item input[type=checkbox] + label:before, .form-item .form-radios .form-item input[type=checkbox] + label:after {
  border-radius: 0;
}
.form-item .form-checkboxes .form-item input[type=radio]:checked + label:after, .form-item .form-checkboxes .form-item input[type=checkbox]:checked + label:after, .form-item .form-radios .form-item input[type=radio]:checked + label:after, .form-item .form-radios .form-item input[type=checkbox]:checked + label:after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.form-item .form-managed-file .form-submit {
  padding: 0;
  line-height: inherit;
}
.form-item input, .form-item textarea {
  border: 2px solid #000;
  padding: 8px 15px;
  font-size: 13px;
  width: 100%;
  font-weight: 600;
}
.form-item textarea {
  height: 120px;
}
.form-item textarea:focus, .form-item input:focus, .form-item input[type]:focus {
  border-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 0, 0, 0.6);
  outline: 0 none;
}
.form-item .dropdown {
  border: 2px solid #000;
  padding: 0;
  color: #000;
  font-weight: 600;
  width: 100%;
}
.form-item .dropdown select {
  height: auto;
  padding: 5px 15px;
  width: 100%;
}
.form-item .description {
  padding-top: 5px;
}
.form-item textarea.error,
.form-item input.error {
  border-color: #000 !important;
}
.form-item .form-required {
  color: #000;
}

.form-item + .form-item, #edit-submitted-fichier-ajax-wrapper {
  margin-top: 9px;
}

.user-login-form {
  max-width: 600px;
  padding: 30px 15px;
  margin: 0 auto;
}

body .views-exposed-form .views-submit-button {
  padding: 0;
}
body .views-exposed-form .views-submit-button input {
  width: 100%;
}

.views-submit-button,
.form-actions {
  text-align: center;
  margin: 30px auto 0;
  position: relative;
  max-width: 150px;
  z-index: 10;
}
.views-submit-button::after,
.form-actions::after {
  content: "";
  height: 38px;
  width: 100%;
  position: absolute;
  left: 4px;
  bottom: -4px;
  background: url(../images/buttonafter-capellia.svg) repeat left top #E6E6E6;
  background-size: 18px 18px;
  z-index: -1;
}
.views-submit-button:hover::after,
.form-actions:hover::after {
  animation: barberpole 10s linear infinite;
}

.jam .form-actions::after {
  background: url(../images/buttonafter.svg) repeat left top #FFCA45;
  background-size: 18px 18px;
}

.btn-submit span {
  max-width: 250px;
  margin-top: 0;
}

.btn-submit {
  padding: 0;
  max-width: 200px;
  margin-top: 30px;
}
.btn-submit + .item-list {
  margin-top: 20px;
}
.btn-submit + .item-list ul li {
  margin: 0;
  padding: 0;
}
.btn-submit + .item-list ul li a {
  color: #000;
  font-weight: bold;
  text-decoration: underline;
}

body.page-user .form-actions::after {
  display: none;
}

#edit-submitted-fichier-ajax-wrapper input[type=submit] {
  border: none;
  padding: 10px 0;
  text-align: left;
}
#edit-submitted-fichier-ajax-wrapper input[type=submit]:hover {
  background-color: #FFF;
}

body .form-item input.error {
  border: 2px solid #DE1313 !important;
}

/*====================
/4 theme du site
======================*/
html {
  overflow-x: hidden;
}

/* animation sur les a href */
a, a:hover,
nav a:hover::after, nav a::after,
.menu-ancres a:hover::after, .menu-ancres a::after,
input[type=submit], input[type=submit]:hover,
.event input[type=radio]:hover ~ label, .event input[type=radio] ~ label {
  -webkit-transition: all 0.5s ease-in-out;
  -khtml-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

@keyframes barberpole {
  100% {
    background-position: 100% 100%;
  }
}
/* animation des blocs */
.js .anim {
  opacity: 0;
  -webkit-transition: all 900ms ease;
  -khtml-transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
  -webkit-transform: translate3d(0px, 100px, 0px);
  -khtml-transform: translate3d(0px, 100px, 0px);
  -moz-transform: translate3d(0px, 100px, 0px);
  -ms-transform: translate3d(0px, 100px, 0px);
  -o-transform: translate3d(0px, 100px, 0px);
  transform: translate3d(0px, 100px, 0px);
}

.js .anim.active {
  opacity: 1;
  -webkit-transition: all 900ms ease;
  -khtml-transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
  -webkit-transform: translate3d(0px, 0px, 0px);
  -khtml-transform: translate3d(0px, 0px, 0px);
  -moz-transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

/*************************************** COOKIES ****************************************************/
.iccookies_container, body #tarteaucitronAlertBig {
  top: auto;
  bottom: 0;
  z-index: 1000;
  background-color: #000;
  opacity: 1;
  font-weight: 500;
  font-style: italic;
  display: flex;
  align-items: center;
  padding: 10px 5%;
  justify-content: center;
  font-size: 12px;
}
.iccookies_container a, body #tarteaucitronAlertBig a {
  color: #FFF;
}
.iccookies_container a:hover, body #tarteaucitronAlertBig a:hover {
  text-decoration: underline;
}
.iccookies_container .close_iccookies, .iccookies_container #tarteaucitronPersonalize, body #tarteaucitronAlertBig .close_iccookies, body #tarteaucitronAlertBig #tarteaucitronPersonalize {
  background-color: #FFF;
  font-weight: 400;
  font-style: normal;
  width: auto;
  text-align: center;
  display: inline-block;
  margin-left: 8px;
  line-height: 25px;
  color: #000;
}

.iccookies_container ~ .layout .langue select,
.iccookies_container ~ .layout .wrap-hamburger,
.iccookies_container ~ .layout #logo {
  margin-top: 50px;
}

.iccookies_container[style="display: none;"] ~ .layout .langue select,
.iccookies_container[style="display: none;"] ~ .layout .wrap-hamburger,
.iccookies_container[style="display: none;"] ~ .layout #logo {
  margin-top: 0;
}

.visually-hidden {
  display: none;
  opacity: 0;
  height: 0;
  width: 0;
}

/* base IC */
a {
  text-decoration: none;
  color: #E90051;
}

body {
  font: normal 17px/25px, Arial, Helvetica, sans-serif;
  color: #000;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 53px;
  color: #000;
  margin-bottom: 15px;
  line-height: 55px;
}

h2 {
  font-size: 34px;
  color: #000;
}

h3 {
  font-size: 17px;
  color: #000;
}

.legend {
  color: #000;
  font-size: 15px;
  margin: 10px 0;
}

body .main-container {
  width: 75%;
  margin: 2em auto;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

body {
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.wrap {
  margin: 0 auto;
  width: 1100px;
}

#menu-header a:hover {
  text-decoration: underline;
}

.wrap::after {
  display: block;
  clear: both;
  content: " ";
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site-content {
  flex: 1;
}

@media all {
  /*************************************** MESSAGES ****************************************************/
  .wrap-messages {
    width: 620px;
    position: fixed;
    top: 10px;
    left: 50%;
    margin-left: -310px;
    z-index: 10000;
    background: #FFF;
  }

  .alert {
    padding: 15px;
    font-weight: bold;
  }
  .alert .close {
    position: absolute;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    top: 0px;
    right: 0px;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
  }
  .alert-error {
    color: #000;
    background-color: rgba(0, 0, 0, 0.03);
  }
  .alert-success {
    color: #62A55B;
    background-color: rgba(98, 165, 91, 0.03);
  }
  .alert-warning {
    color: #004379;
    background-color: rgba(0, 67, 121, 0.03);
  }

  /*************************************** HEADER ****************************************************/
  header {
    padding: 50px 0 40px;
  }
  header .flex {
    align-items: center;
    position: relative;
  }
  header .flex .newsletter {
    margin-left: auto;
    margin-right: 35px;
  }
  header .flex .newsletter .block-webform form > div {
    display: flex;
    position: relative;
    margin-top: -30px;
    padding-right: 53px;
  }
  header .flex .newsletter .block-webform form > div .form-item input {
    width: 240px;
  }
  header .flex .newsletter .block-webform form > div .form-actions {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
  }
  header .flex .newsletter .block-webform form > div .form-actions .form-submit {
    border-width: 2px;
    padding: 9px 20px 8px;
  }
  header .flex .social {
    margin-right: 75px;
  }
  header .flex .social ul li {
    display: inline-block;
  }
  header .flex .social ul li + li {
    margin-left: 5px;
  }

  .region-newsletter .block-mailchimp-signup h2 {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .region-newsletter .block-mailchimp-signup .mailchimp-signup-subscribe-form {
    position: relative;
    padding-right: 63px;
  }
  .region-newsletter .block-mailchimp-signup .mailchimp-signup-subscribe-form .form-actions {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
  }
  .region-newsletter .block-mailchimp-signup .mailchimp-signup-subscribe-form .form-actions input[type=submit] {
    border-width: 2px;
    height: 36px;
    width: 65px;
  }

  /*************************************** DIAPORAMA ****************************************************/
  .diaporama {
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto;
    max-height: 400px;
    overflow: hidden;
  }

  .diaporama .flexslider {
    position: relative;
    min-height: 300px;
  }
  .diaporama .flexslider .flex-direction-nav {
    display: none;
  }
  .diaporama .flexslider .flex-control-nav {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    text-align: center;
  }
  .diaporama .flexslider .flex-control-nav li {
    display: inline-block;
  }
  .diaporama .flexslider .flex-control-nav li + li {
    margin-left: 7px;
  }
  .diaporama .flexslider .flex-control-nav li a {
    display: block;
    background: #FFF;
    text-indent: -999999px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid transparent;
  }
  .diaporama .flexslider .flex-control-nav li a.flex-active {
    background: transparent;
    border: 2px solid #FFF;
  }
  .diaporama .flexslider .slides li {
    position: relative;
  }
  .diaporama .flexslider .slides li > img {
    min-height: 300px;
    object-fit: cover;
  }
  .diaporama .flexslider .slides li span.caption {
    color: #FFF;
    position: absolute;
    bottom: 15%;
    text-align: center;
    margin: auto;
    left: 0;
    right: 0;
  }
  .diaporama .flexslider .slides li span.caption .title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 35px;
    display: block;
    margin-bottom: 10px;
  }
  .diaporama .flexslider .slides li span.caption .date {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 28px;
    display: block;
  }
  .diaporama .flexslider .slides li span.caption .link a {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 41px;
    color: #FFF;
    display: inline-block;
    padding: 4px 8px;
    margin-top: 10px;
  }

  /*************************************** ACCUEIL ****************************************************/
  a.bouton {
    border: 4px solid #000;
    padding: 10px 25px;
    position: relative;
    background-color: #FFF;
    display: inline-block;
    text-decoration: none;
    color: #000;
  }
  a.bouton:hover::after {
    animation: barberpole 10s linear infinite;
  }

  a.bouton::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 8px;
    bottom: -8px;
    background: url(../images/buttonafter-capellia.svg) left top #E6E6E6;
    background-size: 18px 18px;
    z-index: -1;
  }

  main.jam a.bouton::after {
    background: url(../images/buttonafter.svg) left top #FFCA45;
    background-size: 18px 18px;
  }

  /*************************************** CONTENU ****************************************************/
  main.content {
    padding-bottom: 80px;
  }
  main.content p a {
    color: #000;
    text-decoration: underline;
  }
  main.content p a.bouton {
    text-decoration: none;
  }

  /***** fil d'ariane ****/
  #breadcrumbs {
    padding: 20px 0;
    font-size: 11px;
  }

  #breadcrumbs li {
    float: left;
    padding: 0 2px;
  }

  #breadcrumbs li a {
    color: #003C65;
  }

  /******************* CONTENU **************************/
  .message {
    position: fixed;
    background-color: #E6E6E6;
    width: 70%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    bottom: 0;
    z-index: 999;
  }
  .message > * {
    padding: 25px;
  }
  .message .msg-error {
    border: 4px solid #DE1313;
    background-color: #FFF;
  }
  .message .msg-error ul li {
    color: #DE1313;
    font-weight: bold;
  }

  .region-filter .views-exposed-form {
    width: 1100px;
    margin: auto;
  }
  .region-filter .views-exposed-form > .views-exposed-widgets {
    display: inline-block;
    padding: 65px 145px 50px;
    position: relative;
    width: 100%;
  }
  .region-filter .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
    float: none;
    padding: 0;
  }
  .region-filter .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label {
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }
  .region-filter .views-exposed-form > .views-exposed-widgets .bef-checkboxes {
    display: flex;
    flex-wrap: wrap;
  }
  .region-filter .views-exposed-form > .views-exposed-widgets .bef-checkboxes > div {
    width: 25%;
  }

  /***************** Contact (formulaire) *******************************


  /* Event */
  .content.event .informations {
    background-color: #FFF;
    color: #000;
    padding: 25px;
    text-align: center;
    margin: 20px 0 50px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    border: 3px solid #000;
  }

  .content.event h2 {
    text-transform: uppercase;
    width: auto;
    padding-right: 20px;
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 20px;
    margin-bottom: 0;
  }

  .content.event ul li {
    padding-left: 1em;
    text-indent: 1em;
  }
  .content.event ul li::before {
    content: "• ";
    padding-right: 5px;
  }

  /********************************* Blog ********************************/
  main .view-header {
    position: relative;
  }
  main .view-header h1 {
    text-transform: uppercase;
    display: inline-block;
    width: auto;
    background-color: #FFF;
    padding-right: 20px;
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 40px;
    margin-bottom: 0;
    line-height: inherit;
  }
  main .view-header h1::after {
    top: 25px;
  }
  main .view-header::after {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    background: #000;
    z-index: -1;
    top: 12px;
  }

  .views-exposed-form {
    margin: auto;
    width: 1100px;
  }
  .views-exposed-form .views-exposed-widgets {
    margin-bottom: 70px;
    padding-right: 150px;
    position: relative;
  }
  .views-exposed-form .views-exposed-widgets .views-exposed-widget {
    float: none;
  }
  .views-exposed-form .views-exposed-widgets .views-exposed-widget .form-item .form-checkboxes .bef-checkboxes {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .views-exposed-form .views-exposed-widgets .views-exposed-widget .form-item .form-checkboxes .bef-checkboxes .form-item {
    width: 25%;
  }
  .views-exposed-form .views-submit-button {
    display: block;
    text-align: center;
    margin-bottom: 50px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
  }
  .views-exposed-form .views-submit-button input {
    width: auto;
    margin: 0;
  }

  .view-actualites .flex, .view-agenda-culturel .view-content {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 45px;
    margin-left: -20px;
    margin-right: -20px;
  }
  .view-actualites .flex .item, .view-agenda-culturel .view-content .item {
    position: relative;
    margin-bottom: 35px;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
  }
  .view-actualites .flex .item .field-content > img, .view-agenda-culturel .view-content .item .field-content > img {
    transform: scale(1);
    transition: all 0.5s ease-in-out;
  }
  .view-actualites .flex .item span.text, .view-agenda-culturel .view-content .item span.text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    text-align: left;
  }
  .view-actualites .flex .item span.text > span, .view-agenda-culturel .view-content .item span.text > span {
    display: block;
    position: relative;
    z-index: 10;
  }
  .view-actualites .flex .item span.text .title, .view-agenda-culturel .view-content .item span.text .title {
    text-transform: uppercase;
    font-weight: 700;
    color: #FFF;
    font-size: 25px;
  }
  .view-actualites .flex .item span.text .date, .view-agenda-culturel .view-content .item span.text .date {
    color: #FFF;
  }
  .view-actualites .flex .item span.text .button a, .view-agenda-culturel .view-content .item span.text .button a {
    text-transform: uppercase;
    display: inline-block;
    padding: 9px 15px;
    border: 3px solid #FFF;
    color: #FFF;
    font-size: 16px;
    margin-top: 15px;
    font-weight: bold;
    position: relative;
    transition: 0.08s ease-in;
  }
  .view-actualites .flex .item span.text .button a:before, .view-agenda-culturel .view-content .item span.text .button a:before {
    content: "";
    position: absolute;
    background: #000;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    transition: top 0.09s ease-in;
  }
  .view-actualites .flex .item span.text .button a:hover:before, .view-agenda-culturel .view-content .item span.text .button a:hover:before {
    top: 0;
  }
  .view-actualites .flex .item .views-field-field-image-intro, .view-agenda-culturel .view-content .item .views-field-field-image-intro {
    margin-bottom: 24px;
  }
  .view-actualites .flex .item .views-field-field-image-intro img, .view-agenda-culturel .view-content .item .views-field-field-image-intro img {
    width: 100%;
  }
  .view-actualites .flex .item a, .view-agenda-culturel .view-content .item a {
    display: block;
  }
  .view-actualites .flex .item h2, .view-agenda-culturel .view-content .item h2 {
    border-bottom: none;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 0;
    margin-bottom: 10px;
    padding-right: 90px;
  }
  .view-actualites .flex .item span.date, .view-agenda-culturel .view-content .item span.date {
    font-weight: bold;
  }
  .view-actualites .flex .item:hover .field-content > img, .view-agenda-culturel .view-content .item:hover .field-content > img {
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
  }

  /* pagination */
  .listing-actualites ul.pager {
    margin-bottom: 50px;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .listing-actualites ul.pager li {
    padding: 0;
    margin: 0;
  }
  .listing-actualites ul.pager li a {
    background-color: #000;
    color: white;
    margin: 0;
    padding: 7px 13px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
  }
  .listing-actualites ul.pager li.pager-current {
    margin-top: 4px;
    border-radius: 50%;
    padding: 10px;
    background-color: white;
    color: #000;
    font-size: 20px;
  }
  .listing-actualites ul.pager li.pager-first, .listing-actualites ul.pager li.pager-last, .listing-actualites ul.pager li.pager-previous, .listing-actualites ul.pager li.pager-next {
    display: none;
  }
  .listing-actualites ul.pager li + li {
    margin-left: 7px;
  }

  /******************* BACK TO TOP **************************/
  .sf-back-to-top {
    display: block;
    z-index: 999;
    position: fixed;
    right: 40px;
    bottom: 40px;
    width: 50px;
    height: 50px;
    padding: 10px;
    border: 3px solid #000;
    background-color: #FFF;
  }

  .sf-back-to-top:hover {
    border-radius: 50%;
  }

  /*************************************** PIED DE PAGE ****************************************************/
  footer {
    background-color: #000;
    color: #FFF;
    padding: 60px 0 30px;
  }
  footer .wrap > div + div {
    margin-top: 60px;
  }
  footer .region-blocbottom {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }
  footer .region-blocbottom ul {
    column-count: 2;
  }
  footer .region-blocbottom ul li {
    text-transform: uppercase;
    padding-left: -1em;
    text-indent: 1em;
  }
  footer .region-blocbottom ul li::before {
    content: ">";
    padding-right: 5px;
  }
  footer .region-blocbottom ul li a {
    color: #FFF;
  }
  footer .region-blocbottom ul li a:hover {
    text-decoration: underline;
  }
  footer .region-blocbottom .block + .block {
    margin-left: 50px;
  }
  footer .flex {
    justify-content: space-between;
  }
  footer .flex .menufooter ul li {
    text-transform: uppercase;
    display: inline-block;
    padding: 0;
    margin: 0;
  }
  footer .flex .menufooter ul li + li a, footer .flex .menufooter ul li + li span {
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #FFF;
  }
  footer .flex .menufooter ul li a {
    color: #FFF;
  }
  footer .flex .IC {
    float: right;
  }
  footer .flex .IC img {
    width: 150px;
  }
}
.view-actualites .flex .item .linkabove-agenda a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 999;
}

.view-actualites .flex .item .image img {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.view-actualites .flex .item:hover .image img {
  -webkit-transition: all 0.5s ease-in-out;
  -webkit-transform: scale(1.1);
  transition: all 0.5s ease-in-out;
  transform: scale(1.1);
}

footer.home {
  background-color: #FFF;
  padding: 0px 0 30px;
  color: #000;
}

.view-actualites .flex .item span.text .button a, .view-agenda-culturel .view-content .item span.text .button a {
  border: none;
  padding: 0;
  margin: 0;
}

body .ajax-progress, body .ajax-progress-throbber {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  vertical-align: middle;
  text-align: center;
}
body .ajax-progress .throbber {
  position: relative;
  top: 50%;
  left: 50%;
  width: 50px;
  /* dimensions of the ajax-loader.gif */
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  background: url(../images/loader.svg) no-repeat;
  background-size: 50px 50px;
  color: #000;
  z-index: 501;
}

body .krumo-root {
  text-align: left;
}

/* pages */
main.content.contact .bloccontact, body.page-user form {
  border: 4px solid #000;
  padding: 65px 145px 50px;
  position: relative;
  background-color: #FFF;
}
main.content.contact .bloccontact .flex, body.page-user form .flex {
  align-items: center;
}
main.content.contact .bloccontact .flex > div, body.page-user form .flex > div {
  flex: 1;
}
main.content.contact .bloccontact .flex .leftcontent, body.page-user form .flex .leftcontent {
  padding: 15px;
}
main.content.contact .bloccontact .flex .leftcontent h1, body.page-user form .flex .leftcontent h1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 33px;
  margin-bottom: 30px;
}
main.content.contact .bloccontact .flex .leftcontent .contenttext, body.page-user form .flex .leftcontent .contenttext {
  padding-left: 15px;
  position: relative;
  color: #000;
}
main.content.contact .bloccontact .flex .leftcontent .contenttext::before, body.page-user form .flex .leftcontent .contenttext::before {
  content: "";
  background-color: #000;
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
main.content.contact .bloccontact .flex .leftcontent .contenttext h2, body.page-user form .flex .leftcontent .contenttext h2 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 21px;
  font-size: 2.1rem;
  padding-bottom: 0;
  border-bottom: 0;
  margin-bottom: 5px;
}
main.content.contact .bloccontact .flex .leftcontent .contenttext p, body.page-user form .flex .leftcontent .contenttext p {
  line-height: 28px;
}

main.content.contact .bloccontact::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 15px;
  bottom: -15px;
  background: url(../images/buttonafter-capellia.svg) left top #E6E6E6;
  background-size: 18px 18px;
  z-index: -1;
}

.jam main.content.contact .bloccontact::after {
  background: url(../images/buttonafter.svg) left top #FFCA45;
  background-size: 18px 18px;
}

.biblio main.content.contact .bloccontact::after {
  background: url(../images/buttonafter-biblio.svg) left top #B91F61;
  background-size: 18px 18px;
}

body.page-user .region-content .content {
  margin: 0 auto 80px;
  width: 1100px;
}

.cnil {
  margin: 30px 0 50px;
  font-style: italic;
  font-size: 12px;
  font-size: 1.2rem;
}

.jam main.contact .bloccontact .flex .leftcontent .contenttext::before {
  background-color: #FBDA00;
}

.contentflexible .bloc + .bloc {
  margin-top: 90px;
}

.contentflexible .view-header h2 {
  text-transform: uppercase;
  display: inline-block;
  width: auto;
  background-color: #FFF;
  padding-right: 20px;
  font-size: 28px;
  font-weight: 700;
  padding-bottom: 30px;
  margin-bottom: 0;
}

.view-header::after {
  height: 3px;
}

.contentflexible .bloc + .view-header {
  margin-top: 60px;
}

.contentflexible .bloc {
  width: 100%;
  display: inline-block;
}
.contentflexible .bloc .view-content {
  font-weight: 500;
  font-size: 15px;
}
.contentflexible .bloc .view-content h2 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}
.contentflexible .bloc .view-content h3 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.contentflexible .bloc .view-content ul {
  margin: 10px 0;
}
.contentflexible .bloc .view-content ul li {
  text-indent: -1em;
  padding-left: 1em;
}
.contentflexible .bloc .view-content ul li::before {
  content: "• ";
  color: #000;
  padding-right: 5px;
}

.bloc.liens .flex {
  flex-wrap: wrap;
  justify-content: space-between;
}
.bloc.liens .flex > div .item, .bloc.liens .flex > .item {
  position: relative;
  overflow: hidden;
}
.bloc.liens .flex > div .item > img, .bloc.liens .flex > .item > img {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.bloc.liens .flex > div .item a, .bloc.liens .flex > .item a {
  display: block;
  height: 100%;
}
.bloc.liens .flex > div .item a > img, .bloc.liens .flex > .item a > img {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.bloc.liens .flex > div .item span.caption, .bloc.liens .flex > .item span.caption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  padding: 15px;
  text-transform: uppercase;
  font-weight: 700;
  color: #FFF;
  font-size: 25px;
  right: 0;
  left: 0;
}
.bloc.liens .flex > div .item span.text, .bloc.liens .flex > .item span.text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  text-align: left;
}
.bloc.liens .flex > div .item span.text > span, .bloc.liens .flex > .item span.text > span {
  display: block;
  position: relative;
  z-index: 10;
}
.bloc.liens .flex > div .item span.text .title, .bloc.liens .flex > .item span.text .title {
  text-transform: uppercase;
  font-weight: 700;
  color: #FFF;
  font-size: 25px;
}
.bloc.liens .flex > div .item span.text .date, .bloc.liens .flex > .item span.text .date {
  color: #FFF;
}
.bloc.liens .flex > div .item span.text .button a, .bloc.liens .flex > .item span.text .button a {
  text-transform: uppercase;
  display: inline-block;
  /*padding: 9px 15px;
  border: 3px solid #fff;*/
  color: #FFF;
  font-size: 16px;
  margin-top: 15px;
  font-weight: bold;
  position: relative;
  transition: 0.08s ease-in;
}
.bloc.liens .flex > div .item span.text .button a:before, .bloc.liens .flex > .item span.text .button a:before {
  content: "";
  position: absolute;
  background: #000;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  transition: top 0.09s ease-in;
}
.bloc.liens .flex > div .item span.text .button a:hover:before, .bloc.liens .flex > .item span.text .button a:hover:before {
  top: 0;
}
.bloc.liens .flex .item:hover > img, .bloc.liens .flex .item:hover a > img {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.bloc.liens + .bloc.liens {
  margin-top: 35px;
}

.bloc.imagetextebloc .flex {
  align-items: center;
}
.bloc.imagetextebloc .flex > div {
  flex: 1;
}
.bloc.imagetextebloc .flex .image {
  margin-right: 85px;
}

.bloc.liens .flex > .item span.link a {
  height: auto;
  color: #000;
}

.bloc.liens .flex > .item span.text .button.plus, .bloc.liens .flex > div .item span.text > span.button.plus {
  text-transform: uppercase;
  display: inline-block;
  /*padding: 9px 15px;
  border: 3px solid #fff;*/
  color: #FFF;
  font-size: 16px;
  margin-top: 15px;
  font-weight: bold;
  position: relative;
  transition: 0.08s ease-in;
}
.bloc.liens .flex > .item span.text .button.plus:before, .bloc.liens .flex > div .item span.text > span.button.plus:before {
  content: "";
  position: absolute;
  background: #000;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  transition: top 0.09s ease-in;
}
.bloc.liens .flex > .item span.text .button.plus:hover:before, .bloc.liens .flex > div .item span.text > span.button.plus:hover:before {
  top: 0;
}

.bloc.liens.fourimage .flex .bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.bloc.video .view-content-video {
  position: relative;
}
.bloc.video .view-content-video .caption {
  position: absolute;
  left: 20px;
  bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: #FFF;
}

.bloc.imagetextebloc:nth-child(odd) .image {
  order: 2;
  margin-right: 0px;
  margin-left: 85px;
}

.bloc.imagetextebloc + .bloc.imagetextebloc {
  margin-top: 40px;
}

.bloc.liens.actualites .item > .image {
  display: block;
  margin-bottom: 20px;
  overflow: hidden;
}
.bloc.liens.actualites .item > .image > img {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}

.bloc.liens.actualites .item:hover > .image img {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.bloc.liens.actualites .item span.title {
  border-bottom: none;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 0;
  margin-bottom: 0px;
  padding-right: 90px;
  display: block;
}
.bloc.liens.actualites .item span.title a {
  color: #000;
}

.bloc.liens.fullimage img {
  min-height: 250px;
  object-fit: cover;
}

.bloc.liens.actualites .item span.date {
  font-weight: 700;
  display: block;
}

.content.home .field-collection-container {
  border-bottom: 0;
  margin-bottom: 0;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content.home .wrap > div + div {
  margin-top: 50px;
}

.content.home .view-header h2 {
  text-transform: uppercase;
  display: inline-block;
  width: auto;
  background-color: #FFF;
  padding-right: 20px;
  font-size: 28px;
  font-weight: 700;
  padding-bottom: 30px;
  margin-bottom: 0;
}

.content.home .view-header::after {
  height: 3px;
}

.content.home .bloc + .view-header {
  margin-top: 60px;
}

.content .etaussi .field-collection-container, .agendahome .field-collection-container {
  display: flex;
  justify-content: space-between;
}
.content .etaussi .field-collection-container .item, .agendahome .field-collection-container .item {
  position: relative;
  padding: 0;
  border-bottom: 0px;
  margin: 0;
  overflow: hidden;
  width: 31%;
}
.content .etaussi .field-collection-container .item a, .agendahome .field-collection-container .item a {
  display: block;
}
.content .etaussi .field-collection-container .item a img, .agendahome .field-collection-container .item a img {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.content .etaussi .field-collection-container .item span.caption, .agendahome .field-collection-container .item span.caption {
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  padding: 15px;
  text-transform: uppercase;
  font-weight: 700;
  color: #FFF;
  font-size: 25px;
  right: 0;
  left: 0;
}

.content .etaussi .field-collection-container .item:hover img {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.agendahome .item span.image {
  margin-bottom: 20px;
  overflow: hidden;
  display: block;
}
.agendahome .item span.image > img {
  transition: all 0.5s ease-in-out;
  transform: scale(1);
}

.agendahome .item:hover span.image img {
  transition: all 0.5s ease-in-out;
  transform: scale(1.1);
}

.agendahome .clearfix:after, .etaussi .clearfix::after {
  display: none;
}

.agendahome .item span.title {
  border-bottom: none;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 0;
  margin-bottom: 0px;
  padding-right: 90px;
  display: block;
}
.agendahome .item span.title a {
  color: #000;
}

.agendahome .item span.date, .agendahome .item span.link {
  font-weight: 700;
  display: block;
}

.agendahome .item span.link a {
  color: #000;
  display: inline-block;
}

.content.home .entete .wrap {
  width: 1250px;
  padding: 70px 0;
}
.content.home .entete .wrap .bloctop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content.home .entete .wrap .bloctop .field-collection-container {
  display: flex;
  align-items: stretch;
  width: 70%;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc {
  position: relative;
  padding: 160px 15px 170px;
  flex: 1;
  border: 4px solid #000;
  background-color: #FFF;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc span {
  text-transform: uppercase;
  display: block;
  text-align: center;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc span.title {
  font-size: 25px;
  font-weight: 700;
  line-height: initial;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc span.subtitle {
  font-size: 17px;
  font-weight: 500;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc span.link a {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 600;
  bottom: 100px;
  font-size: 27px;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc + .bloc {
  margin-left: 35px;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 15px;
  bottom: -15px;
  background: url(../images/buttonafter-capellia.svg) left top #E6E6E6;
  background-size: 18px 18px;
  z-index: -1;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc:hover::after {
  animation: barberpole 20s linear infinite;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc.capellia span.link a {
  color: #E6E6E6;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc.jam span.link a {
  color: #FFCA45;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc.biblio span.link a {
  color: #B91F61;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc.jam::after {
  background: url(../images/buttonafter.svg) left top #FFCA45;
  background-size: 18px 18px;
}
.content.home .entete .wrap .bloctop .field-collection-container .bloc.biblio::after {
  background: url(../images/buttonafter-biblio.svg) left top #B91F61;
  background-size: 18px 18px;
}

.content.home .entete .wrap .bloctop .field-collection-container .bloc a.linkabove {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.remonteeagenda .pager--infinite-scroll, .agendahome .pager {
  text-align: center;
}
.remonteeagenda .pager--infinite-scroll a, .agendahome .pager a {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  -webkit-appearance: none;
  border: 4px solid #000;
  background-color: #FFF;
  color: #000;
  font-weight: 600;
  font-size: 17px;
  text-transform: uppercase;
  padding: 10px 25px;
  margin-bottom: 50px;
  text-decoration: none;
  position: relative;
}
.remonteeagenda .pager--infinite-scroll a::after, .agendahome .pager a::after {
  content: "";
  height: 43px;
  width: 100%;
  position: absolute;
  left: 8px;
  bottom: -8px;
  background: url(../images/buttonafter-capellia.svg) left top #E6E6E6;
  background-size: 18px 18px;
  z-index: -1;
}
.remonteeagenda .pager--infinite-scroll a:hover::after, .agendahome .pager a:hover::after {
  animation: barberpole 10s linear infinite;
}

.content .etaussi .field-collection-container .item a {
  display: block;
  min-height: 320px;
  min-width: 340px;
  position: relative;
  background-color: #100F49;
}
.content .etaussi .field-collection-container .item a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #100F49;
  opacity: 0.5;
  z-index: 1;
}

.view-agenda-culturel .view-content .item .linkabove-agenda a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 999;
}

.remonteeagenda .item span.image {
  overflow: hidden;
  display: block;
}
.remonteeagenda .item span.image > img {
  transition: all 0.5s ease-in-out;
  transform: scale(1);
}

.remonteeagenda .item:hover span.image img {
  transition: all 0.5s ease-in-out;
  transform: scale(1.1);
}

/*====================
/7 menus
======================*/
.menu-trigger {
  width: 31px;
  height: 31px;
  margin: 0;
  position: relative;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  bottom: 6px;
  z-index: 999999;
  transform: translateY(-50%);
}
.menu-trigger span {
  display: block;
  background: #000;
  border-radius: 0;
}

.bandeauhome {
  overflow: hidden;
}

.bandeauhome img {
  transform: scale(1);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.bandeauhome a:hover img {
  transform: scale(1.1);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.menu-trigger #hamburger {
  position: absolute;
  height: 100%;
  width: 100%;
}
.menu-trigger #hamburger span {
  width: 31px;
  height: 4px;
  position: relative;
  top: 0;
  left: 0;
  margin: 5px 0;
}
.menu-trigger #hamburger:nth-child(1) {
  transition-delay: 0.5s;
}
.menu-trigger #hamburger:nth-child(2) {
  transition-delay: 0.625s;
  width: 15px;
}
.menu-trigger #hamburger:nth-child(3) {
  transition-delay: 0.75s;
}

.menu-trigger #cross {
  position: fixed;
  height: 31px;
  width: 31px;
  transform: rotate(45deg);
  right: 5px;
}
.menu-trigger #cross span:nth-child(1) {
  height: 0%;
  width: 4px;
  position: absolute;
  top: 0%;
  left: 14px;
  transition-delay: 0s;
  background: #FFF;
}
.menu-trigger #cross span:nth-child(2) {
  width: 0%;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 13px;
  transition-delay: 0.25s;
  background: #FFF;
}

.menu-trigger.active #hamburger span {
  width: 0%;
}
.menu-trigger.active #hamburger span:nth-child(1) {
  transition-delay: 0s;
}
.menu-trigger.active #hamburger span:nth-child(2) {
  transition-delay: 0.125s;
}
.menu-trigger.active #hamburger span:nth-child(3) {
  transition-delay: 0.25s;
}

.menu-trigger.active #cross span:nth-child(1) {
  height: 31px;
  transition-delay: 0.625s;
}

.menu-trigger.active #cross span:nth-child(2) {
  width: 31px;
  transition-delay: 0.375s;
}

.menu-basic {
  position: fixed;
  right: 0;
  top: 0;
  width: 500px;
  height: 100%;
  background-color: #000;
  display: block;
  transform: translateX(100%);
  transition: all 0.8s cubic-bezier(0.99, 0.01, 0.02, 0.99);
  z-index: 99999;
  padding: 100px 50px;
}
.menu-basic nav {
  width: 100%;
}
.menu-basic nav .content > ul.menu {
  display: inline-block;
  padding-left: 0;
  width: 100%;
}
.menu-basic nav .content > ul.menu > li {
  display: block;
  margin: 0;
  padding: 0 15px;
  line-height: 20px;
}
.menu-basic nav .content > ul.menu > li > a, .menu-basic nav .content > ul.menu > li > span {
  font-size: 18px;
  color: #FFF;
}
.menu-basic nav .content > ul.menu > li > a:hover, .menu-basic nav .content > ul.menu > li > span:hover {
  color: #E6E6E6;
}
.menu-basic nav .content > ul.menu li + li {
  margin-top: 40px;
}
.menu-basic nav .content > ul.menu {
  margin-top: 80px;
}
.menu-basic nav .content > ul.menu > li {
  flex: 1;
}
.menu-basic nav .content > ul.menu > li > span.nolink, .menu-basic nav .content > ul.menu > li > a {
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 15px;
  margin-bottom: 20px;
  display: block;
  font-size: 21px;
  letter-spacing: 0.07em;
  position: relative;
}
.menu-basic nav .content > ul.menu > li > span.nolink::after, .menu-basic nav .content > ul.menu > li > a::after {
  content: "";
  width: 40px;
  height: 3px;
  background: #FFF;
  position: absolute;
  left: 0;
  bottom: 0;
}
.menu-basic nav .content > ul.menu > li ul li.leaf, .menu-basic nav .content > ul.menu > li ul li {
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 0;
  transition: all 0.5s ease-in-out;
}
.menu-basic nav .content > ul.menu > li ul li.leaf:hover, .menu-basic nav .content > ul.menu > li ul li:hover {
  padding-left: 10px;
}
.menu-basic nav .content > ul.menu > li ul li.leaf + li, .menu-basic nav .content > ul.menu > li ul li + li {
  margin-top: 10px;
}
.menu-basic nav .content > ul.menu > li ul li.leaf a, .menu-basic nav .content > ul.menu > li ul li.leaf span, .menu-basic nav .content > ul.menu > li ul li a, .menu-basic nav .content > ul.menu > li ul li span {
  font-size: 15px;
  color: #FFF;
  text-transform: uppercase;
}
.menu-basic nav .content > ul.menu > li ul li.leaf a ul, .menu-basic nav .content > ul.menu > li ul li.leaf span ul, .menu-basic nav .content > ul.menu > li ul li a ul, .menu-basic nav .content > ul.menu > li ul li span ul {
  margin-top: 10px;
}
.menu-basic nav .content > ul.menu > li ul li.leaf a ul li a, .menu-basic nav .content > ul.menu > li ul li.leaf span ul li a, .menu-basic nav .content > ul.menu > li ul li a ul li a, .menu-basic nav .content > ul.menu > li ul li span ul li a {
  text-transform: initial;
}

.menu-basic.open {
  transform: translateX(0);
}

.menu-trigger.active {
  position: fixed;
  top: 10%;
  right: 3%;
}

.menu-basic nav .content > ul.menu ul.menu ul.menu {
  margin: 10px 0;
}

.menu-basic nav .content > ul.menu ul.menu > li a, .menu-basic nav .content > ul.menu ul.menu > li span {
  text-transform: uppercase;
  color: #FFF;
}

.menu-basic nav .content > ul.menu ul.menu ul.menu > li a, .menu-basic nav .content > ul.menu ul.menu ul.menu > li span {
  text-transform: initial;
  color: #FFF;
}

/*====================
/5 utilitaires responsive
======================*/
/*====================
/Responsive (Mobiles, tablettes, ...)
======================*/
/* Ecrans HDPI
=================*/
@media (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
  /* Images @2x */
}
@media only screen and (min-width: 1025px) {
  .desk-hide {
    display: none;
  }
}
/* Environnement mobile : Tablettes & Téléphones
==================================================*/
@media only screen and (max-width: 1024px) {
  /* Masquer certains éléments sur environnement mobile */
  .mob-hide {
    display: none !important;
  }

  .mob-show {
    display: block !important;
  }

  .mob-show-inline {
    display: inline-block !important;
  }

  /* Responsive media object */
  .media-mob > .media-item, .media-mob > .media-item-rev {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .media-mob.media-small > .media-item, .media-mob.media-small > .media-item-rev {
    margin-bottom: 15px;
  }

  .media-mob.media-large > .media-item, .media-mob.media-large > .media-item-rev {
    margin-bottom: 60px;
  }

  /**
   * On défini les tailles de grilles sur environnement mobile
   */
  /* Entier */
  .mob-full {
    width: 100%;
  }

  /* Moitiés */
  .mob-half {
    width: 50%;
  }

  /* on utilise le mixin de grid pour les tailles spécifiques au mobile
  ici grilles 4 et 8 colonnes */
  .grids .mob-1-4 {
    width: 25%;
  }
  .grids .mob-2-4 {
    width: 50%;
  }
  .grids .mob-3-4 {
    width: 75%;
  }
  .grids .mob-4-4 {
    width: 100%;
  }
  .grids .mob-1-8 {
    width: 12.5%;
  }
  .grids .mob-2-8 {
    width: 25%;
  }
  .grids .mob-3-8 {
    width: 37.5%;
  }
  .grids .mob-4-8 {
    width: 50%;
  }
  .grids .mob-5-8 {
    width: 62.5%;
  }
  .grids .mob-6-8 {
    width: 75%;
  }
  .grids .mob-7-8 {
    width: 87.5%;
  }
  .grids .mob-8-8 {
    width: 100%;
  }
}
/* Phablet (Gros téléphone mobile)
==================================================*/
@media only screen and (max-width: 767px) {
  /* Masquer certains éléments sur environnement mobile */
  .phab-hide {
    display: none !important;
  }

  .phab-show {
    display: block !important;
  }

  .phab-show-inline {
    display: inline-block !important;
  }

  /* Responsive media object */
  .media-phab > .media-item, .media-phab > .media-item-rev {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .media-phab.media-small > .media-item, .media-phab.media-small > .media-item-rev {
    margin-bottom: 15px;
  }

  .media-phab.media-large > .media-item, .media-phab.media-large > .media-item-rev {
    margin-bottom: 60px;
  }

  /**
   * On défini les tailles de grilles sur environnement mobile
   */
  /* Entier */
  .grids .phab-full,
.phab-full {
    width: 100%;
  }

  /* Moitiés */
  .grids .phab-half,
.phab-half {
    width: 50%;
  }

  /* on utilise le mixin de grid pour les tailles spécifiques aux phablettes
  ici grilles 4 et 8 colonnes */
  .grids .phab-1-4 {
    width: 25%;
  }
  .grids .phab-2-4 {
    width: 50%;
  }
  .grids .phab-3-4 {
    width: 75%;
  }
  .grids .phab-4-4 {
    width: 100%;
  }
  .grids .phab-1-8 {
    width: 12.5%;
  }
  .grids .phab-2-8 {
    width: 25%;
  }
  .grids .phab-3-8 {
    width: 37.5%;
  }
  .grids .phab-4-8 {
    width: 50%;
  }
  .grids .phab-5-8 {
    width: 62.5%;
  }
  .grids .phab-6-8 {
    width: 75%;
  }
  .grids .phab-7-8 {
    width: 87.5%;
  }
  .grids .phab-8-8 {
    width: 100%;
  }
}
/* Téléphones
==================================================*/
@media only screen and (max-width: 465px) {
  /* Masquer certains éléments sur environnement mobile */
  .palm-hide {
    display: none !important;
  }

  .palm-show {
    display: block !important;
  }

  .palm-show-inline {
    display: inline-block !important;
  }

  /* Responsive media object */
  .media-palm > .media-item, .media-palm > .media-item-rev {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .media-palm.media-small > .media-item, .media-palm.media-small > .media-item-rev {
    margin-bottom: 15px;
  }

  .media-palm.media-large > .media-item, .media-palm.media-large > .media-item-rev {
    margin-bottom: 60px;
  }

  /* Grids
  =========*/
  .palm-row {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .palm-row > .mob-cell {
    display: table-cell;
    vertical-align: top;
  }

  .palm-unrow {
    display: block;
  }

  .palm-unrow > .cell {
    display: inline-block;
  }

  /**
   * On défini les tailles de grilles sur environnement mobile
   */
  /* Entier */
  .grids .palm-full,
.palm-full {
    width: 100%;
  }

  /* Moitiés */
  .grids .palm-half,
.palm-half {
    width: 50%;
  }

  /* on utilise le mixin de grid pour les tailles spécifiques aux phablettes
  ici grilles 4 et 8 colonnes */
  .grids .palm-1-2 {
    width: 50%;
  }
  .grids .palm-2-2 {
    width: 100%;
  }
  .grids .palm-1-4 {
    width: 25%;
  }
  .grids .palm-2-4 {
    width: 50%;
  }
  .grids .palm-3-4 {
    width: 75%;
  }
  .grids .palm-4-4 {
    width: 100%;
  }
  .grids .palm-1-8 {
    width: 12.5%;
  }
  .grids .palm-2-8 {
    width: 25%;
  }
  .grids .palm-3-8 {
    width: 37.5%;
  }
  .grids .palm-4-8 {
    width: 50%;
  }
  .grids .palm-5-8 {
    width: 62.5%;
  }
  .grids .palm-6-8 {
    width: 75%;
  }
  .grids .palm-7-8 {
    width: 87.5%;
  }
  .grids .palm-8-8 {
    width: 100%;
  }

  /* Tableaux responsive
  ========================*/
  /**
   * Pour afficher des données lisibles sur petits écrans, on change le sens de lecture
   * Les en-tête sont affichées devant chaque élément de colonne

    En-tête 1 | En-tête 2 | En-tête 3
    ----------------------------------
    Col 1     | Col 2     | Col 3
    Col 4     | Col 5     | Col 6

   * Devient

     En-tête 1 | Col 1
     En-tête 2 | Col 2
     En-tête 3 | Col 3
     ------------------
     En-tête 1 | Col 4
     En-tête 2 | Col 5
     En-tête 3 | Col 6
     ------------------

   * Pour simuler ces en-tête, un data-attr `data-label` doit être appliqué à la main sur les cellules
   * Ce data-attr doit reprendre l'intitulé de l'en-tête correspondant
   <table class="table-responsive">
       <caption>Liste des employés</caption>
       <thead>
           <tr>
               <th>Nom</th>
               <th>Prénom</th>
           </tr>
       </thead>
       <tbody>
           <tr>
               <td data-label="Nom">Employé 1</td>
               <td data-label="Prénom">Employé 2</td>
           </tr>
       </tbody>
   </table>
   */
  .table-responsive, .table-responsive thead, .table-responsive tbody, .table-responsive th, .table-responsive td, .table-responsive tr {
    display: block;
  }

  .table-responsive thead {
    border-bottom: 1px solid;
  }

  /* Les entêtes sont masqués */
  .table-responsive thead th {
    position: absolute;
    top: -9999em;
    left: -9999em;
  }

  /**
   * On ne garde que le premier en-tête qui devient le titre du tableau 
   * Le mieux étant d'avoir une `<caption></caption>`
   */
  .table-responsive thead th:first-child {
    position: static;
  }

  /* Séparation entre chaque "entrée" du tableau */
  .table-responsive tbody tr {
    border-bottom: 1px solid;
  }

  /* On créé un espace vide pour laisser la place à l'en-tête généré via data-* */
  .table-responsive td {
    padding-left: 50%;
    position: relative;
  }

  /* Si une ligne n'a pas besoin de label */
  .table-responsive .tr-heading {
    padding-left: 10px;
    text-align: center;
  }

  /* L'en-tête que nous avons masqué est récupéré via un data-attr */
  .table-responsive tbody td:before {
    content: " " attr(data-label) " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: right;
  }

  /**
   * Corriger bug sur IOS 5.1.1 lié à Modernizr et un élément affiché hors de l'écran
   * Tous les éléments avec des coordonnées les positionnant en dehors de l'écran doivent être ajoutés ici
   */
  .i-txt,
.nav-children .children,
.hidden,
.table-responsive thead th {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/*====================
/6 theme responsive
======================*/
/*====================
/ Responsive (Mobiles, tablettes, ...)
======================*/
/* Ecrans HDPI
=================*/
@media (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
  /* Images @2x */
}
@media only screen and (max-width: 1350px) {
  .content.home .entete .wrap {
    width: 96%;
  }

  .menu-basic {
    padding: 100px 50px;
  }
  .menu-basic nav {
    width: 100%;
  }
  .menu-basic nav .content > ul.menu {
    display: block;
    margin-top: 80px;
  }

  .view-actualites .flex .item, .view-agenda-culturel .view-content .item {
    width: 32%;
    max-width: 340px;
  }
}
@media only screen and (max-width: 1150px) {
  .wrap {
    width: 96%;
  }

  .bloc.liens.fourimage .flex .left {
    flex: 2;
  }

  .bloc.liens.fourimage .flex .right {
    flex: 1;
    margin-left: 20px;
  }

  .content.home .entete .wrap .bloctop {
    display: block;
    text-align: center;
  }
  .content.home .entete .wrap .bloctop .field-collection-container {
    margin-top: 20px;
    width: 100%;
  }

  .menu-trigger.active {
    right: 2%;
  }

  .bloc.liens .flex > .bigimage {
    flex: 2;
  }

  .bloc.liens .flex > .smallimage {
    flex: 1;
    margin-left: 15px;
  }
  .bloc.liens .flex > .smallimage:first-child {
    margin-left: 0;
    margin-right: 15px;
  }

  .bloc.liens.actualites .flex > .item, .bloc.liens.dividedthree .flex > .item, .agendahome .field-collection-container .item, .content .etaussi .field-collection-container .item {
    flex: 1;
  }

  .bloc.liens.actualites .flex > .item + .item, .bloc.liens.dividedthree .flex > .item + .item, .agendahome .field-collection-container .item + .item, .content .etaussi .field-collection-container .item + .item {
    margin-left: 10px;
  }

  .bloc.liens.dividedthree .flex > .item > img {
    min-height: 350px;
    object-fit: cover;
  }

  .bloc.liens.dividedthree .flex > .item > img {
    width: 100%;
  }

  footer .logofooter {
    text-align: center;
  }

  footer .region-blocbottom .block {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
  footer .region-blocbottom .block + .block {
    margin-left: 0;
  }

  .region-filter .views-exposed-form, .views-exposed-form {
    width: 96%;
    max-width: 1100px;
  }
}
/* Environnement small screen
==================================================*/
@media only screen and (max-width: 1024px) {
  body.page-user form, main.content.contact .bloccontact {
    padding: 65px 35px 50px;
  }

  .bloc.imagetextebloc .flex > div, body.page-user form .flex > div, main.content.contact .bloccontact .flex > div {
    flex: 1.5;
  }

  .region-filter .views-exposed-form > .views-exposed-widgets .bef-checkboxes > div, .views-exposed-form .views-exposed-widgets .views-exposed-widget .form-item .form-checkboxes .bef-checkboxes .form-item {
    width: 33%;
  }

  .bloc.imagetextebloc .flex .image {
    margin-right: 15px;
    flex: 1;
  }

  .bloc.liens .flex > .item a, .bloc.liens .flex > div .item a {
    display: block;
  }

  .bloc.imagetextebloc:nth-child(odd) .image {
    margin-left: 15px;
  }

  .view-actualites .flex .item, .view-agenda-culturel .view-content .item {
    width: 44%;
  }
  .view-actualites .flex .item .field-content > img, .view-agenda-culturel .view-content .item .field-content > img {
    width: 100%;
  }

  .bigimage img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .fourimage.liens .flex > div .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 622px;
  }

  .bloc.liens .flex > .bigimage, .bloc.liens.fourimage .flex .left {
    flex: 1;
  }
  .bloc.liens .flex > .bigimage img, .bloc.liens.fourimage .flex .left img {
    width: 100%;
    object-fit: cover;
  }

  .bloc.liens .flex > .smallimage img, .bloc.liens.fourimage .flex .right img {
    width: 100%;
    object-fit: cover;
  }

  .liens.fourimage .bottom .item,
.liens.fourimage .item.first {
    min-height: 293px;
    max-height: 293px;
    overflow: hidden;
  }
  .liens.fourimage .bottom .item img,
.liens.fourimage .item.first img {
    width: 100%;
    height: 100%;
    min-height: 293px;
    min-height: 293px;
    object-fit: cover;
  }

  .bloc.liens.fourimage .flex .bottom .item:first-child {
    margin-right: 8px;
  }
  .bloc.liens.fourimage .flex .bottom .item:first-child + .item {
    margin-left: 8px;
  }
}
/* Environnement mobile : Tablettes & Téléphones
==================================================*/
@media only screen and (max-width: 850px) {
  .bloc.imagetextebloc:nth-child(odd) .image {
    order: 1;
  }

  .region-filter .views-exposed-form > .views-exposed-widgets .bef-checkboxes > div, .views-exposed-form .views-exposed-widgets .views-exposed-widget .form-item .form-checkboxes .bef-checkboxes .form-item {
    width: 49%;
    padding: 0;
  }

  .logo .region-logo .content img {
    max-width: 200px;
  }

  .bloc.liens .flex > .item span.text, .bloc.liens .flex > div .item span.text {
    padding: 15px;
  }

  .bloc.liens .flex > .smallimage, .bloc.liens.fourimage .flex,
.bloc.liens .flex > .bigimage, .bloc.liens.fourimage .flex {
    flex-wrap: wrap;
  }

  .bloc.liens + .bloc.liens,
.bloc.liens .flex > .smallimage:first-child,
.bloc.liens .flex > .smallimage {
    margin: 0;
  }

  .bloc.liens.fourimage .flex .bottom,
.bloc.liens .flex,
.bloc.liens .flex > .smallimage, .bloc.liens.fourimage .flex .right,
.bloc.liens .flex > .bigimage, .bloc.liens.fourimage .flex .left {
    width: 100%;
    display: block;
    margin: 0;
  }
  .bloc.liens.fourimage .flex .bottom .item,
.bloc.liens .flex .item,
.bloc.liens .flex > .smallimage .item, .bloc.liens.fourimage .flex .right .item,
.bloc.liens .flex > .bigimage .item, .bloc.liens.fourimage .flex .left .item {
    width: 100%;
    display: block;
    margin: 0;
    min-height: 400px !important;
    max-height: 400px !important;
    margin-bottom: 15px;
    overflow: hidden;
  }
  .bloc.liens.fourimage .flex .bottom .item img,
.bloc.liens .flex .item img,
.bloc.liens .flex > .smallimage .item img, .bloc.liens.fourimage .flex .right .item img,
.bloc.liens .flex > .bigimage .item img, .bloc.liens.fourimage .flex .left .item img {
    min-height: 400px !important;
    max-height: 400px !important;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .liens.fourimage .bottom .item, .liens.fourimage .item.first {
    width: 100%;
  }

  .bloc.liens.fourimage .flex .bottom .item:first-child,
.bloc.liens.fourimage .flex .bottom .item:first-child + .item {
    margin: 0;
  }

  .bloc.liens.fourimage .flex .bottom .item:first-child + .item,
.bloc.liens.fourimage .flex .bottom .item:first-child,
.bloc.liens .flex > .smallimage, .bloc.liens .flex > .smallimage:first-child {
    margin-bottom: 15px;
  }

  .bloc.imagetextebloc .flex {
    flex-direction: column;
  }

  .content .etaussi .field-collection-container .item a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content .etaussi .field-collection-container .item:last-child {
    margin: 0;
    margin-top: 10px;
    max-height: 350px;
  }
  .content .etaussi .field-collection-container .item:last-child a {
    max-height: 350px;
  }
  .content .etaussi .field-collection-container .item:last-child a img {
    max-height: 350px;
  }
}
/* Phablet (Gros téléphone mobile)
==================================================*/
@media only screen and (max-width: 767px) {
  body {
    overflow: hidden;
  }

  .menu-basic {
    width: 100%;
  }

  .menu-basic nav .content > ul.menu {
    margin-top: 0;
  }
  .menu-basic nav .content > ul.menu > li {
    width: 100%;
  }

  .views-exposed-form .views-exposed-widgets {
    padding-right: 0px;
  }

  .views-exposed-form .views-submit-button {
    position: relative;
    top: 0;
    transform: none;
    left: 0px;
  }

  .region-filter .views-exposed-form > .views-exposed-widgets .bef-checkboxes > div, .views-exposed-form .views-exposed-widgets .views-exposed-widget .form-item .form-checkboxes .bef-checkboxes .form-item {
    width: 100%;
  }

  header .flex .newsletter {
    display: none;
  }

  header .flex .social {
    margin-left: auto;
  }

  .content .etaussi .field-collection-container .item a {
    height: 100%;
  }
  .content .etaussi .field-collection-container .item a img {
    height: 100%;
    object-fit: cover;
  }

  .menu-trigger.active {
    top: 50px;
    right: 50px;
  }

  .content.home .entete .wrap .bloctop .field-collection-container, .content .etaussi .field-collection-container {
    display: block;
  }
  .content.home .entete .wrap .bloctop .field-collection-container .bloc, .content .etaussi .field-collection-container .bloc {
    padding: 60px 15px 90px;
  }
  .content.home .entete .wrap .bloctop .field-collection-container .bloc span.link a, .content .etaussi .field-collection-container .bloc span.link a {
    bottom: 50px;
  }
  .content.home .entete .wrap .bloctop .field-collection-container .bloc + .bloc, .content .etaussi .field-collection-container .bloc + .bloc {
    margin-left: 0;
    margin-top: 30px;
  }

  .content .etaussi .field-collection-container .item {
    width: 70%;
    margin: 0 auto 20px;
  }
  .content .etaussi .field-collection-container .item a > img {
    width: 100%;
  }
  .content .etaussi .field-collection-container .item + .item {
    margin-left: auto;
  }

  body.page-user form, main.content.contact .bloccontact {
    padding-top: 50px;
  }

  body.page-user form .flex, main.content.contact .bloccontact .flex {
    display: block;
  }
  body.page-user form .flex .leftcontent, main.content.contact .bloccontact .flex .leftcontent {
    margin-bottom: 30px;
  }

  .bloc.liens.actualites .flex > .item, .agendahome .field-collection-container .item {
    display: flex;
    align-items: center;
    flex: auto;
    width: 100%;
  }
  .bloc.liens.actualites .flex > .item > *, .agendahome .field-collection-container .item > * {
    flex: 1;
    width: 50%;
  }
  .bloc.liens.actualites .flex > .item span.desc, .agendahome .field-collection-container .item span.desc {
    margin-left: 20px;
  }
  .bloc.liens.actualites .flex > .item + .item, .agendahome .field-collection-container .item + .item {
    margin-left: 0;
  }

  .content .etaussi .field-collection-container .item {
    min-height: 300px;
    height: 300px;
  }

  .content .etaussi .field-collection-container .item:last-child {
    margin-right: auto;
  }

  .view-actualites .flex .item span.text .title, .view-agenda-culturel .view-content .item span.text .title {
    font-size: 22px;
  }

  .message {
    width: auto;
    margin: 0;
    left: 15px;
    right: 15px;
  }

  .views-exposed-form .views-exposed-widget .form-item .form-checkboxes .bef-checkboxes .form-item {
    width: 50%;
    padding: 6px 0;
  }

  .content .etaussi .field-collection-container .item a {
    min-width: auto;
  }
}
/* Téléphones
==================================================*/
@media only screen and (max-width: 600px) {
  .bloc.liens.dividedthree .flex {
    display: block;
  }

  .view-actualites .flex .item, .view-agenda-culturel .view-content .item, .bloc.liens.dividedthree .flex > .item {
    width: 80%;
    margin: 0 auto 20px;
  }

  .bloc.liens.dividedthree .flex > .item > img {
    width: 100%;
  }

  .bloc.liens.dividedthree .flex > .item + .item {
    margin-left: auto;
  }

  .bloc.liens.actualites .flex > .item, .agendahome .field-collection-container .item, .content .etaussi .field-collection-container .item {
    display: block;
    margin-bottom: 40px;
  }
  .bloc.liens.actualites .flex > .item > *, .agendahome .field-collection-container .item > *, .content .etaussi .field-collection-container .item > * {
    flex: auto;
    width: 100%;
  }
  .bloc.liens.actualites .flex > .item span.desc, .agendahome .field-collection-container .item span.desc, .content .etaussi .field-collection-container .item span.desc {
    margin-left: 0;
  }

  footer .flex {
    display: block;
    text-align: center;
  }

  footer .flex .IC {
    float: none;
  }

  ul.menu {
    text-align: center;
  }

  .menu-basic nav .content > ul.menu > li > a::after, .menu-basic nav .content > ul.menu > li > span.nolink::after {
    right: 0;
    margin: auto;
  }

  footer .region-blocbottom ul {
    column-count: 1;
  }

  .diaporama .flexslider .slides li img {
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 465px) {
  header .flex, header .logo {
    display: block;
  }

  header .flex .social {
    position: absolute;
    top: -20px;
    right: 10px;
    margin-right: 0;
  }

  .content .etaussi .field-collection-container .item {
    width: 100%;
  }

  .content.event .bandeauevent img {
    min-height: 250px;
    object-fit: cover;
  }

  .view-actualites .flex .item, .view-agenda-culturel .view-content .item, .bloc.liens.dividedthree .flex > .item {
    width: 100%;
  }

  footer .flex .menufooter ul li {
    display: block;
  }

  footer .flex .menufooter ul li + li a {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }
}
/* fix font */
body,
[class^=grid-],
.v-align-wrap {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  line-height: 24px;
}